import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import './Gallery.css';
import MUAY_THAI_CORNER from '../assets/MUAY_THAI_CORNER.png';
import POST_FIGHT_WINNER_COACHES_REFEREE from '../assets/POST_FIGHT_WINNER_COACHES_REFEREE.png';
import POST_FIGHT_WINNER_ALONE from '../assets/POST_FIGHT_WINNER_ALONE.png';
import postfight from '../assets/postfight.png';
import kbmt_gallery_image from '../assets/kbmt_gallery_image.png';
import kbmtgallery from '../assets/kbmtgallery.png';
import kbmtgalleryone from '../assets/kbmtgalleryone.png';
import kbmtgallerytwo from '../assets/kbmtgallerytwo.png';



function Gallery() {
    const [currentIndex, setCurrentIndex] = useState(0);
    const images = [postfight, kbmt_gallery_image, kbmtgallery, kbmtgalleryone, kbmtgallerytwo, MUAY_THAI_CORNER, POST_FIGHT_WINNER_COACHES_REFEREE, POST_FIGHT_WINNER_ALONE];

    // Function to handle automatic swiping
    const handleAutoSwipe = () => {
        setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    // Start automatic swiping on component mount
    useEffect(() => {
        const intervalId = setInterval(handleAutoSwipe, 3000); // Change image every 3 seconds
        return () => clearInterval(intervalId); // Clear interval on component unmount
    }, []);

    const handlers = useSwipeable({
        onSwipedLeft: () => setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1)),
        onSwipedRight: () => setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1)),
        trackMouse: true // Enable mouse tracking
    });

    const translateValue = -100 * currentIndex + '%';

    return (
        <div className="Gallery" {...handlers}>
            <div className="Gallery-inner" style={{ transform: `translateX(${translateValue})` }}>
                {images.map((image, index) => (
                    <div key={index} className="Image-outer">
                        <img src={image} alt={`Image ${index + 1}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Gallery;




